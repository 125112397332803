import type { PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export function ScrollContainer({ children }: PropsWithChildren) {
  return (
    <Box
      height="100%"
      width="100%"
      flex="1 1 auto"
      justifyContent="stretch"
      overflow="auto"
    >
      {children}
    </Box>
  );
}
