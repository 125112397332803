import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { LinkButton } from '@mentimeter/next-navigation';
import {
  ArrowLeftIcon,
  SettingsIcon,
  CreditCardIcon,
  SlidersIcon,
  UserAddedIcon,
  UsersIcon,
  TrendIcon,
  SendIcon,
  UserCheckIcon,
  QuestionMarkCircleIcon,
} from '@mentimeter/ragnar-visuals';
import { useFeatures } from '@mentimeter/workspace-features';
import {
  MenuItem,
  MenuItemSeparator,
  MenuWrapper,
  MenuItemSection,
  FooterItems,
} from 'features/menu-ui';
import { MenuLogo } from 'features/menu-ui/components/MenuLogo';
import {
  useWorkspace,
  useWorkspaceRequests,
} from '@mentimeter/workspace-data-hooks';
import { REQUESTS } from '../../header/user-actions/components/user-menu/userMenuItems';
import { HelpModalTrigger } from '../../header/user-actions/components/HelpModalTrigger';

function Groups() {
  const { hasGroupsFeature } = useFeatures();
  if (!hasGroupsFeature) return null;

  return (
    <MenuItem
      id="groups-menu-item"
      text="Groups"
      to="/app/groups"
      icon={<UsersIcon />}
    />
  );
}

function WorkspaceInsights() {
  const { hasWorkspaceInsightsFeature } = useFeatures();
  if (!hasWorkspaceInsightsFeature) return null;

  return (
    <MenuItem
      id="workspace-insights-menu-item"
      to="/app/workspace-insights"
      text="Insights"
      icon={<TrendIcon />}
    />
  );
}

function FindAvailableTeams() {
  const { hasFindAvailableTeamsFeature } = useFeatures();
  if (!hasFindAvailableTeamsFeature) return null;

  return (
    <MenuItem
      id="find-workspace-menu-item"
      to="/app/find-your-team"
      text="Find workspace"
      icon={<SendIcon />}
    />
  );
}

function Requests({ workspaceId }: { workspaceId: number }) {
  const { data: workspaceRequests } = useWorkspaceRequests(workspaceId);

  const showBadge =
    workspaceRequests?.totalCount && workspaceRequests.totalCount > 0;

  const displayNumber =
    workspaceRequests?.totalCount && workspaceRequests.totalCount > 99
      ? '99+'
      : String(workspaceRequests?.totalCount);

  return (
    <MenuItem
      id="requests-menu-item"
      data-testid="requests-menu-item"
      text={REQUESTS.key}
      to={REQUESTS.href}
      icon={<UserAddedIcon />}
      badgeType={showBadge ? 'text' : null}
      badgeText={showBadge ? displayNumber : undefined}
    />
  );
}

function Settings() {
  return (
    <MenuItem
      id="settings-menu-item"
      text="Account settings"
      to="/app/settings"
      icon={<SettingsIcon />}
    />
  );
}

function Billing() {
  return (
    <MenuItem
      id="billing-menu-item"
      text="Billing"
      to="/app/billing"
      icon={<CreditCardIcon />}
    />
  );
}

function WorkspaceSettings() {
  return (
    <MenuItem
      id="workspace-settings-menu-item"
      to="/app/workspace-settings"
      text="Workspace settings"
      icon={<SlidersIcon />}
    />
  );
}

function ManageMembers() {
  return (
    <MenuItem
      id="manage-members-menu-item"
      to="/app/manage-members"
      text="Manage members"
      icon={<UserCheckIcon />}
    />
  );
}

function Workspace() {
  const { hasWorkspaceSettingsFeature } = useFeatures();
  return (
    <>
      {hasWorkspaceSettingsFeature && <WorkspaceSettings />}
      <ManageMembers />
    </>
  );
}

function Footer() {
  return (
    <FooterItems>
      <HelpModalTrigger>
        <MenuItem
          id="help-and-support-menu-item"
          text="Help & support"
          icon={<QuestionMarkCircleIcon />}
          compact
        />
      </HelpModalTrigger>
    </FooterItems>
  );
}

export function SettingsMenu() {
  return (
    <MenuWrapper noPadding>
      <MenuLogo />
      <LinkButton variant="subtle" href="/app" iconLeading={ArrowLeftIcon}>
        Back to home
      </LinkButton>
      <SettingsMenuContent />
      <Footer />
    </MenuWrapper>
  );
}

function SettingsMenuContent() {
  const { featureAccessLevel, isLoadingFeatures, hasWorkspaceRequestsPage } =
    useFeatures();
  const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace();

  const isLoading = isLoadingWorkspace || isLoadingFeatures;
  if (isLoading) return null;

  const shouldShowRequestsPage = workspace && hasWorkspaceRequestsPage;

  switch (featureAccessLevel) {
    case UserFeaturesTypeEnum.REGISTERED:
      return (
        <>
          <MenuItemSection>
            <MenuItemSeparator text="My profile" />
            <Settings />
            <Billing />
          </MenuItemSection>

          <MenuItemSection>
            <MenuItemSeparator text="My workspace" />
            <FindAvailableTeams />
            <WorkspaceSettings />
            <ManageMembers />
          </MenuItemSection>
        </>
      );
    case UserFeaturesTypeEnum.BASIC:
    case UserFeaturesTypeEnum.PRO:
      return (
        <>
          <MenuItemSection>
            <MenuItemSeparator text="My profile" />
            <Settings />
          </MenuItemSection>

          <MenuItemSection>
            <MenuItemSeparator text="My workspace" />
            <Billing />
            <FindAvailableTeams />
            <Workspace />
            {shouldShowRequestsPage && <Requests workspaceId={workspace.id} />}
            <Groups />
          </MenuItemSection>
        </>
      );
    case UserFeaturesTypeEnum.ENTERPRISE:
      return (
        <>
          <MenuItemSection>
            <MenuItemSeparator text="My profile" />
            <Settings />
          </MenuItemSection>

          <MenuItemSection>
            <MenuItemSeparator text="My workspace" />
            <Billing />
            <Workspace />
            {shouldShowRequestsPage && <Requests workspaceId={workspace.id} />}
            <Groups />
            <WorkspaceInsights />
          </MenuItemSection>
        </>
      );
    case UserFeaturesTypeEnum.ENTERPRISE_TRIAL:
      return (
        <>
          <MenuItemSection>
            <MenuItemSeparator text="My profile" />
            <Settings />
          </MenuItemSection>

          <MenuItemSection>
            <MenuItemSeparator text="My workspace" />
            <Billing />
            <Workspace />
            <Groups />
            <WorkspaceInsights />
          </MenuItemSection>
        </>
      );
    default:
      return null;
  }
}
