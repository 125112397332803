import { userCache } from '@mentimeter/user';
import {
  getListRequest,
  getListResponse,
  type GetListResponse,
} from '@core-api/workspace-requests/workspaces/{workspace_id}/requests/list';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import useSWR, { mutate } from 'swr';

const WORKSPACE_REQUESTS_SWR_CACHE_KEY = 'workspace-requests';

export const mutateWorkspaceRequests = () =>
  mutate(WORKSPACE_REQUESTS_SWR_CACHE_KEY);

export const useWorkspaceRequests = (workspaceId: number) => {
  const { data, error } = useSWR<GetListResponse>(
    WORKSPACE_REQUESTS_SWR_CACHE_KEY,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();
      const response = await fetch(
        getListRequest({ region, userAuth: sessionToken, workspaceId }),
      );

      if (!response.ok) {
        const e = new MentiError('Error fetching workspace requests', {
          feature: 'workspace-requests',
        });
        captureException(e);
        return {
          inviteRequests: { totalCount: 0, items: [] },
          upgradeRequests: { totalCount: 0, items: [] },
          totalCount: 0,
        };
      }
      const data = await getListResponse(response);
      return data;
    },
  );

  return {
    data,
    error,
  };
};
