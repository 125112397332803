/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import { DashboardEngagementLimitBanner } from '@mentimeter/paywalls/engagement-limit';
import {
  LayoutHeader,
  LayoutMain,
  LayoutRoot,
  LayoutApp,
  LayoutBanners,
  LayoutSidebar,
} from '@mentimeter/dashboard-ui';
import { Desktop } from 'app/(authenticated)/(main)/components/responsive-utils';
import { SettingsHeader } from '../components/header/SettingsHeader';
import Notifications from '../components/notifications';
import { SettingsMenu } from '../components/side-menu/settings-menu/SettingsMenu';

export const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutRoot>
      <LayoutBanners>
        <DashboardEngagementLimitBanner />
      </LayoutBanners>

      <LayoutApp>
        <Desktop>
          <LayoutSidebar>
            <SettingsMenu />
          </LayoutSidebar>
        </Desktop>
        <LayoutMain>
          <LayoutHeader>
            <Notifications />
            <SettingsHeader />
          </LayoutHeader>

          {children}
        </LayoutMain>
      </LayoutApp>
    </LayoutRoot>
  );
};
