// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetListRequest {
  region: 'eu' | 'us';
  userAuth: string;
  workspaceId: number;
}

export function getListRequest(requestParams: GetListRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/workspaces/${requestParams.workspaceId}/requests/list`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
