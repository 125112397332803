import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

type LayoutBannersProps = BoxT;

export function LayoutBanners({ children, ...rest }: LayoutBannersProps) {
  return (
    <Box width="100%" flexDirection="row" {...rest}>
      {children}
    </Box>
  );
}
