import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

type LayoutRootProps = BoxT;

export function LayoutRoot({ children, ...rest }: LayoutRootProps) {
  return (
    <Box bg="bg" height="100%" width="100%" {...rest}>
      {children}
    </Box>
  );
}
